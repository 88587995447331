import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';

const Footer = () => {
  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <span className="back-to-top">
          <Link to="hero" smooth duration={1000}>
            <i className="fa fa-angle-up fa-2x" aria-hidden="true" />
          </Link>
        </span>
        <hr className="mx-auto" />
        <p className="footer__text">
          © {new Date().getFullYear()} - Built with{' '}
          <a href="https://www.gatsbyjs.com/" target="_blank" rel="noopener noreferrer">
            gatsby
          </a>
          , template based on{' '}
          <a
            href="https://github.com/cobidev/gatsby-simplefolio"
            target="_blank"
            rel="noopener noreferrer"
          >
            gatsby-simplefolio
          </a>
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
