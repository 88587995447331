import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import { nanoid } from 'nanoid';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import Publications from './Publications';

const Research = () => {
  const { research } = useContext(PortfolioContext);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="research">
      <hr />
      <Container>
        <div className="research-wrapper">
          <Title title="Publications" />
          {research.map((research) => {
            const { title, author, year, publication, pages, organization, url } = research;

            return (
              <Row key={nanoid()}>
                <Fade
                  left={isDesktop}
                  bottom={isMobile}
                  duration={1000}
                  delay={200}
                  distance="30px"
                >
                  <Col lg={12} sm={12}>
                    <div className="research-wrapper__text" style={{ fontSize: '1.3rem' }}>
                      {author} ({year}). {title}. In {publication}
                      {pages && ` (${pages})`}.{organization && ` ${organization}. `}
                      {url && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={url}
                          style={{ fontSize: '1.3rem' }}
                        >
                          link
                        </a>
                      )}
                    </div>
                  </Col>
                </Fade>
              </Row>
            );
          })}
        </div>
      </Container>
    </section >
  );
};

export default Research;
