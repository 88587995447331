import React, { useContext, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import Fade from 'react-reveal/Fade';
// import Tilt from 'react-tilt';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
// import ProjectImg from '../Image/ProjectImg';

const Projects = () => {
  const { projects } = useContext(PortfolioContext);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          <Title title="Recent Projects" />
          {projects.map((project) => {
            const { title, date, info, url, img, cta } = project;
            // const project_pic = (
            //   <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
            //     <div className="project-wrapper__image">
            //       <a
            //         href={url || '#!'}
            //         target="_blank"
            //         aria-label="Project Link"
            //         rel="noopener noreferrer"
            //       >
            //         <Tilt
            //           options={{
            //             reverse: false,
            //             max: 8,
            //             perspective: 1000,
            //             scale: 1,
            //             speed: 300,
            //             transition: true,
            //             axis: null,
            //             reset: true,
            //             easing: 'cubic-bezier(.03,.98,.52,.99)',
            //           }}
            //         >
            //           <div data-tilt className="thumbnail rounded">
            //             <ProjectImg alt={title} filename={img} />
            //           </div>
            //         </Tilt>
            //       </a>
            //     </div>
            //   </Fade>
            // );

            return (
              <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={200} distance="30px">
                <Row key={nanoid()}>
                  <Col>
                    <h4 className="project-wrapper__text-title">{title}</h4>
                  </Col>
                  <Col xs={2}>
                    <p className="mb-4">{date}</p>
                  </Col>
                  <Col xs={1}>
                    {/* <p className="mb-4">{info}</p> */}
                    {url && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cta-btn cta-btn--hero"
                        href={url}
                      >
                        {cta || 'More'}
                      </a>
                    )}
                  </Col>
                  {/* {img && (
                    <Col lg={6} sm={12}>
                      {project_pic}
                    </Col>
                  )} */}
                </Row>
              </Fade>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default Projects;
